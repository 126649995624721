.swiper-button-prev {
  font-family: pronto-icons !important;
  speak: never;
  font-style: normal;
  font-weight: 400;
  font-feature-settings: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  @apply bg-white rounded-full w-10 h-10 p-0 my-auto top-0 bottom-0 text-white left-0 ml-2 pointer-events-auto !important;
}

.swiper-button-prev:after {
  @apply text-sm font-extrabold text-gray-700 !important;
}

.swiper-button-next {
  font-family: pronto-icons !important;
  speak: never;
  font-style: normal;
  font-weight: 400;
  font-feature-settings: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  @apply bg-white my-auto w-10 h-10 top-0 bottom-0 rounded-full p-0 text-white right-0 mr-2 pointer-events-auto !important;
}

.swiper-button-next:after {
  @apply text-sm font-extrabold text-gray-700 !important;
}

.swiper-button-prev:hover,
.swiper-button-next:hover {
  @apply bg-gray-200 !important;
}
