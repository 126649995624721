.cft-background {
  background: linear-gradient(107.22deg, #054755 1.42%, #3f727c 120.48%);
}

.cft-font-color {
  color: #074956;
}

.cft-light-green-color {
  color: #3bc28a;
}

.cft-bg-light-green-color {
  background: #3bc28a;
}

.cft-custom-border {
  border-color: #22c587;
}

.cft-custom-cyan-border {
  border-color: #49dcf3;
}

.cft-cyan-color {
  color: #49dcf3;
}

.cft-icon-card-container {
  top: -31px;
}

/* Slider related styles */
.rc-slider-track {
  height: 10px !important;
}

.rc-slider-rail {
  background: #8a8a8a !important;
  height: 10px !important;
}

.cft-carbon-slider .rc-slider-track {
  background: #3bc28a !important;
}

.cft-warn-slider .rc-slider-track {
  background: #f59e0b !important;
}

.cft-danger-slider .rc-slider-track {
  background: #ef4444 !important;
}

.cft-water-slider .rc-slider-track {
  background: #49dcf3 !important;
}

/* HANDLER CLASS */
.cft-carbon-slider-handle .rc-slider-handle {
  height: 24px;
  width: 24px;
  top: 2px;
  background: #3bc28a !important;
  border-color: #3bc28a !important;
}

.cft-water-slider-handle .rc-slider-handle {
  height: 24px;
  width: 24px;
  top: 2px;
  background: #49dcf3 !important;
  border-color: #49dcf3 !important;
}

.cft-warn-slider-handle .rc-slider-handle {
  height: 24px;
  width: 24px;
  top: 2px;
  background: #f59e0b !important;
  border-color: #f59e0b !important;
}

.cft-danger-slider-handle .rc-slider-handle {
  height: 24px;
  width: 24px;
  top: 2px;
  background: #ef4444 !important;
  border-color: #ef4444 !important;
}

/* VALUE INSIDE OF HANDLER CLASS */
.rc-slider-handle .cft-handle-value-slider {
  position: absolute;
  top: 20px;
  left: -15px;
  font-weight: bold;
  width: max-content;
}

.cft-carbon-slider-handle .rc-slider-handle .cft-handle-value-slider {
  color: #3bc28a !important;
}

.cft-water-slider-handle .rc-slider-handle .cft-handle-value-slider {
  color: #49dcf3 !important;
}

.cft-warn-slider-handle .rc-slider-handle .cft-handle-value-slider {
  color: #f59e0b !important;
}

.cft-danger-slider-handle .rc-slider-handle .cft-handle-value-slider {
  color: #ef4444 !important;
}

/* slider 2*/

.input-range {
  &__track {
    height: 12px !important;

    &--background {
      background: #8a8a8a !important;
    }
  }
  &__slider {
    svg {
      display: none;
    }
  }
  &__label {
    .input-range__label-container {
      font-weight: bold;
    }

    &--min {
      display: none;
    }
    &--max {
      display: none;
    }
  }
}

.test {
  .input-range {
    &__track {
      &--active {
        background: #3bc28a !important;
        border-color: #3bc28a !important;
      }
    }
    &__slider {
      background: #3bc28a !important;
      border-color: #3bc28a !important;
    }
    &__label {
      font-size: 0.875rem;

      .input-range__label-container {
        color: #3bc28a !important;
        font-weight: bold;
      }
      &--value {
        top: 0px !important;
      }
    }
  }
}

/* slider 3 */
.cft-horizontal-slider {
  width: 100%;
  height: 11px;
}

.cft-horizontal-slider :nth-child(2) {
  top: 0;
  bottom: 0;
  background: gray;
  border-radius: 999px;
}

.cft-slider-carbon-thumb {
  height: 24px;
  line-height: 25px;
  width: 24px;
  text-align: center;
  background-color: #3bc28a;
  color: #3bc28a;
  border-radius: 50%;
  cursor: grab;
  top: -7px;
}

.cft-slider-water-thumb {
  height: 24px;
  line-height: 25px;
  width: 24px;
  text-align: center;
  background-color: #49dcf3;
  color: #49dcf3;
  border-radius: 50%;
  cursor: grab;
  top: -7px;
}

.cft-slider-thumb-warn {
  height: 24px;
  line-height: 25px;
  width: 24px;
  text-align: center;
  background-color: #f59e0b;
  color: #f59e0b;
  border-radius: 50%;
  cursor: grab;
  top: -7px;
}

.cft-slider-thumb-danger {
  height: 24px;
  line-height: 25px;
  width: 24px;
  text-align: center;
  background-color: #ef4444;
  color: #ef4444;
  border-radius: 50%;
  cursor: grab;
  top: -7px;
}

.cft-slider-mark {
  background-color: #3bc28a;
}

.cft-slider-track {
  top: 0;
  bottom: 0;
  background: gray;
  border-radius: 999px;
}

.range-horizontal-slider {
  width: 100%;
  height: 11px;
}

.range-slider-track {
  top: 0;
  bottom: 0;
  background: gray;
  border-radius: 999px;
}
.range-slider {
  top: 0;
  bottom: 0;
  background: gray;
  border-radius: 999px;
}

.range-slider :nth-child(2) {
  top: 0;
  bottom: 0;
  background: #3bc28a;
  border-radius: 999px;
}

.range-slider-thumb {
  height: 24px;
  line-height: 25px;
  width: 24px;
  text-align: center;
  background-color: #114c3a;
  color: #114c3a;
  border-radius: 50%;
  cursor: grab;
  top: -7px;
}

.cft-slider-carbon-success {
  top: 0;
  bottom: 0;
  background: #3bc28a;
  border-radius: 999px;
}

.cft-slider-water-success {
  top: 0;
  bottom: 0;
  background: #49dcf3;
  border-radius: 999px;
}

.cft-slider-warn {
  top: 0;
  bottom: 0;
  background: #f59e0b;
  border-radius: 999px;
}

.cft-slider-danger {
  top: 0;
  bottom: 0;
  background: #ef4444;
  border-radius: 999px;
}

/*  cft/dashboard */

.cft-record {
  background: #cef3e0;
  border-color: #3bc28a;
}
.carbon-progress .CircularProgressbar-path {
  stroke: url(#carbonGradient);
}

.water-progress .CircularProgressbar-path {
  stroke: url(#waterGradient);
}

/* quantity form */

.quantity-button-wrapper {
  width: 90vw;
}

/* CF profile */

.half-colored-border {
  border-radius: 50%;
  border: 8px solid;
  border-color: #408bc3 #408bc3 #22c587 #22c587;
  transform: rotate(45deg);
}

.half-colored-border-small {
  border-radius: 50%;
  border: 6px solid;
  border-color: #408bc3 #408bc3 #22c587 #22c587;
  transform: rotate(45deg);
}

.half-colored-border-transform {
  transform: rotate(-45deg);
}

.cft-bg-warn {
  background: #f59e0b !important;
}
.cft-bg-danger {
  color: #ef4444 !important;
}

.custom-img {
  position: relative !important;
}

.custom-slider > .slick-prev:before,
.slick-next:before {
  color: black !important;
}

.custom-slider-initiative > .slick-prev:before,
.slick-next:before {
  color: black !important;
}

.custom-slider-initiative > .slick-next {
  right: 2px;
  z-index: 30;
}

.custom-slider-initiative > .slick-prev {
  left: 2px;
  z-index: 30;
}

.react-calendar {
  width: auto !important;
}
