@tailwind base;
@tailwind components;
@tailwind utilities;

html,
body {
  font-family: 'Urbanist', sans-serif;
  background-color: #f1f4f6;
  scroll-behavior: smooth;
}

@keyframes pulse {
  0% {
    transform: scale(1, 1);
  }

  50% {
    transform: scale(1.2, 1.2);
  }

  100% {
    transform: scale(1, 1);
  }
}

/* Custom animations */
.out-expo {
  transition: all 0.8s cubic-bezier(0, 1, 0, 1);
}

.in-out-cubic {
  transition: all 0.6s cubic-bezier(0.65, 0, 0.35, 1);
}

.connected-max-height {
  max-height: 600px;
}

.custom-logo {
  animation: pulse 1.5s cubic-bezier(0.5, 1, 0.89, 1) infinite;
}

.truncate {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.truncate-3 {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
}

.line-clamp-2 {
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

.line-clamp-3 {
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

.line-clamp-1 {
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

@keyframes rotator {
  0% {
    transform-origin: 50% 50%;
  }
  100% {
    transform: rotate(360deg);
  }
}

@keyframes dash {
  0% {
    stroke-dasharray: 1px, 200px;
    stroke-dashoffset: 0;
  }
  50% {
    stroke-dasharray: 100px, 200px;
    stroke-dashoffset: -15px;
  }
  100% {
    stroke-dasharray: 100px, 200px;
    stroke-dashoffset: -125px;
  }
}

.Spinner {
  display: inline-block;
  animation: rotator 1.4s linear infinite;
}

.Spinner .Path {
  stroke-dasharray: 80px, 200px;
  stroke-dashoffset: 0;
  animation: dash 1.4s ease-in-out infinite;
  animation-fill-mode: forwards;
  stroke: currentColor;
}

/** Youtube iframe **/
.youtube-iframe-container {
  @apply relative w-full h-0;
  padding-bottom: 56.25%;
}

.listbox button {
  @apply w-full h-4;
}

/* Safe Area insets */
.bottom-safe-area-padding {
  padding-bottom: env(safe-area-inset-bottom);
}

.react-horizontal-scrolling-menu--inner-wrapper {
  @apply relative;
}

.react-horizontal-scrolling-menu--arrow-left {
  @apply absolute h-full items-center z-20 p-2;
}

.react-horizontal-scrolling-menu--arrow-right {
  @apply absolute right-0 h-full items-center z-20 p-2;
}

.react-horizontal-scrolling-menu--scroll-container {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}

.react-horizontal-scrolling-menu--scroll-container::-webkit-scrollbar {
  display: none;
}

.react-horizontal-scrolling-menu--item {
  @apply pr-5;
}

.sunet-shadow {
  box-shadow: 0px 2px 27px rgba(49, 79, 124, 0.08);
}

body {
  overflow-y: scroll;
}

::-webkit-scrollbar-thumb {
  @apply bg-primary;
  border-radius: 4px;
  outline: none;
}

::-webkit-scrollbar {
  width: 8px;
  height: 8px;
}

::-webkit-scrollbar-track {
  @apply bg-gray-300;
}

.ranking-position {
  background: linear-gradient(270deg, #156d73 -3.29%, #61b478 107%);
}

/** Post detail image **/
.post-image {
  height: 100%;
}

.post-detail-with-image {
  @screen lg {
    height: calc(100vh - 80px);
  }
}

.post-image img {
  pointer-events: none;
}

// .post-image .background,
// .blur-image {
//   filter: blur(10px);
//   transform: scale(1.2);
// }

/* Rich text styles */
.rich {
  @apply text-gray-700;
}

.rich h1 {
  @apply text-3xl font-bold border-b mb-8;
}

.rich h2 {
  @apply text-xl font-bold;
}

.rich h3 {
  @apply text-lg font-bold;
}

.rich a {
  &:hover {
    @apply underline;
  }
}

.rich ol {
  @apply list-decimal;
}

.rich ul {
  list-style: inherit;
}

.rich ol,
.rich ul {
  @apply pl-5;
}

.rich p {
  @apply mb-5;
}

.hide-scrollbar::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.hide-scrollbar {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}

.video-responsive {
  height: 0;
  overflow: hidden;
  padding-bottom: 56.25%;
  padding-top: 30px;
  position: relative;
  border-radius: 1rem;
}

.video-responsive iframe,
.video-responsive object,
.video-responsive embed {
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
}

input[type='radio'] {
  visibility: hidden; /* hide default radio button */
  margin-bottom: 4px;
  /* you may need to adjust margin here, too */
}

input[type='radio']::before {
  /* create pseudoelement */
  border: 2px solid #38c16f; /* thickness, style, color */
  height: 1em; /* height adjusts with font */
  width: 1em; /* width adjusts with font */
  border-radius: 50%; /* make it round */
  display: block; /* or flex or inline-block */
  content: ' '; /* won't display without this */
  cursor: pointer; /* appears clickable to mouse users */
  visibility: visible; /* reverse the 'hidden' above */
}

input[type='radio']:checked::before {
  /* selected */
  /* add middle dot when selected */
  /* slightly bigger second value makes it smooth */
  /* even more (e.g., 20% 50%) would make it fuzzy */
  background: radial-gradient(#38c16f 36%, transparent 38%);
}
